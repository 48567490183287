import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import About from '../pages/About';

import Homepage from '../pages/homepage';
import Loading from '../pages/Loading';
import PageNotFound from '../pages/PageNotFound';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Homepage} />
    <Route path="/about" component={About} />
    <Route path="/loading" component={Loading} />
    <Route path="/404" component={PageNotFound} />
    <Redirect to="/404" />
  </Switch>
);

export default Routes;
