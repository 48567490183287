import styled, { css } from 'styled-components';

interface BackgroundProps {
  visibility?: boolean;
}

export const Container = styled.button<BackgroundProps>`
  background-color: transparent;
  display: none;
  align-items: center;
  border: none;
  cursor: none;

  position: absolute;

  right: 1rem;
  top: 40%;

  button {
    border: none;
    background-color: transparent;
  }

  @media (max-width: 1199px) {
    display: flex;
  }
`;

export const Background = styled.div<BackgroundProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);

  width: 100%;
  height: auto;

  transition: transform 0.5s ease-in-out;

  border-radius: 0 0 15px 15px;

  ${props =>
    props.visibility
      ? css`
          transform: translateY(0%);
        `
      : css`
          transform: translateY(-101%);
        `};

  z-index: 25;

  position: absolute;
  right: 0;
  top: 0;

  button {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 10%;
    right: 1rem;

    transform: rotate(-90deg);
  }
`;

export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 15%;

  li {
    color: transparent;
    margin-bottom: 1rem;
  }

  a {
    font-size: 5vw;
    color: #171717;
  }

  span {
    font-size: 5vw;
    color: #171717;
  }
`;
