/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ButtonHTMLAttributes, useState } from 'react';
import { Link } from 'react-router-dom';
// import { CustomDialog } from 'react-st-modal';

import { Container, Logo } from './styles';
// import ModalRegister from '../ModalRegister';
import ButtonMenuMobile from './components/ButtonMenuMobile';

type HeaderProps = ButtonHTMLAttributes<HTMLButtonElement>;

const Header: React.FC<HeaderProps> = ({ ...rest }) => {
  const [scrollY, setScrollY] = useState(window.scrollY);

  window.addEventListener('scroll', () => {
    setScrollY(window.scrollY);
  });

  return (
    <Container
      style={{
        backgroundColor: scrollY === 0 ? 'transparent' : '#171717',
        borderBottom: scrollY === 0 ? '0.5px solid #999' : '0px',
      }}
      {...rest}
    >
      <ButtonMenuMobile />
      <Logo>
        <Link to="/">Plific</Link>
      </Logo>
      <ul>
        <li>
          <Link to="/about">Sobre nós</Link>
        </li>
        <li>
          <span
            onClick={() =>
              // CustomDialog(<ModalRegister />)
              window.open(
                'https://api.whatsapp.com/send?phone=5521982015754&text=Ol%C3%A1,%20quero%20fazer%20parte!',
                '_blank',
                'noopener,noreferrer',
              )
            }
          >
            Fazer parte
          </span>
        </li>
      </ul>
    </Container>
  );
};

export default Header;
