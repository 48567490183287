import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;

  box-sizing: border-box;
  padding: 0 0 0 5rem;

  transition: background-color 0.5s linear;

  position: fixed;
  z-index: 999;

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;

    li {
      margin-left: 3vw;

      a {
        font-size: 1rem;
        font-weight: 500;
        transition: all 0.5s ease-in-out;
      }

      &:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        background-color: #eec03f;

        box-sizing: border-box;
        padding: 0 2rem;
        cursor: pointer;
        font-size: 1vw;

        border-radius: 0;
      }
    }
  }

  a,
  button {
    margin: 0;
  }

  @media (max-width: 1199px) {
    justify-content: center;
    margin-right: 0;

    h1 {
      margin: 0;
    }

    ul {
      display: none;
    }
  }
`;

export const Logo = styled.h1`
  font-size: 30px;
  font-weight: bolder;
  font-family: 'Montserrat Alternates';
  cursor: pointer;
  display: flex;
  align-items: center;

  &::after {
    content: '.';
    color: #eec03f;
  }

  @media (max-width: 1199px) {
    margin-left: 0;
    position: absolute;
    left: 1rem;
    top: 22%;
  }
`;
